<template>
  <Modal v-if="isOpen">
    <div class="w-full flex justify-end mx-10">
      <Card class="w-10/12 relative right-0 py-5">
        <template>
          <div class="flex flex-col justify-start items-start gap-3 px-2">
            <h1 class="text-xl text-left font-extrabold mx-3">
              Summary Report
            </h1>
            <Divider class="w-full" />
          </div>
        </template>
        <div v-if="isLoading" class="w-full h-80">
          <loader size="xxs" :loader-image="false" />
        </div>
        <template v-else>
          <div class="mx-3 flex flex-col gap-2">
            <div class="overflow-y-auto h-80">
              <Table
                :headers="headers"
                :items="employeeReviews"
                :has-number="false"
                class="w-full"
                :loading="isLoading"
              >
                <template v-slot:item="{ item }">
                  <div v-if="item.employee" class="font-normal text-sm">
                    <div class="flex flex-col justify-start items-start">
                      <span class="text-darkPurple">
                        {{ item.data.employee.fname }}  {{ item.data.employee.lname }}
                      </span>
                      <span class="text-romanSilver">
                        {{ item.data.employee.designation }}
                      </span>
                    </div>
                  </div>
                  <div v-if="item.serviceYears" class="font-normal text-sm">
                    <span class="text-darkPurple">{{ item.data.serviceYears }}</span>
                  </div>
                  <div v-if="item.rangeMidPoint" class="font-normal text-sm">
                    <span class="text-darkPurple">
                      {{ convertToCurrency(item.data.rangeMidPoint) }}
                    </span>
                  </div>
                  <div v-if="item.currentGrossPay" class="font-normal text-sm">
                      <span class="text-darkPurple">
                        {{ convertToCurrency(item.data.currentGrossPay) }}
                      </span>
                  </div>
                  <div v-if="item.change" class="font-normal text-sm">
                    <span class="text-darkPurple">
                      {{ item.data.change }}
                    </span>
                  </div>
                  <div v-if="item.increase" class="font-normal text-sm">
                    <span class="text-darkPurple">
                      {{ convertToCurrency(item.data.increase) }}
                    </span>
                  </div>
                  <div v-if="item.newGrossPay" class="font-normal text-sm">
                    <span class="text-darkPurple">
                      {{ convertToCurrency(item.data.newGrossPay) }}
                    </span>
                  </div>
                </template>
              </Table>
            </div>
            <div class="flex self-end pt-3">
              <Button
                label="Close"
                @onClick="isOpen= false"
                variant="primary btn-bg button-style"
              />
            </div>
          </div>
        </template>
      </Card>
    </div>
  </Modal>
</template>

<script>
  import Button from "@scelloo/cloudenly-ui/src/components/button"
  import Loader from "@scelloo/cloudenly-ui/src/components/loader"
  import Table from "@scelloo/cloudenly-ui/src/components/table"
  import Card from "@scelloo/cloudenly-ui/src/components/card"
  import Modal from "@/components/Modal"
  import Divider from "@/components/divider"

  export default {
    components: {
      Button,
      Loader,
      Table,
      Card,
      Modal,
      Divider,
    },
    data(){
      return {
        isOpen: false,
        isLoading: true,
        employeeReviews: [],
        headers: [
          { title: "employee", value: "employee" },
          { title: "length of service", value: "serviceYears" },
          { title: "range mid-point", value: "rangeMidPoint" },
          { title: "current gross pay", value: "currentGrossPay" },
          { title: "change (%)", value: "change" },
          { title: "change (ngn)", value: "increase" },
          { title: "new gross pay", value: "newGrossPay" },
        ],
      }
    },
    methods: {
      toggle(reviewId){
        this.isOpen = true
        this.isLoading = true
        this.employeeReviews = []
        this.getCompensationReviewDetailsById({ reviewId })
      },
      async getCompensationReviewDetailsById(payload){
        await this.$_getCompensationReviewDetails(payload).then(({ data }) => {
          this.employeeReviews = data.data?.map((item) => ({
            ...item, ...item.employee, paygradeId: item.paygrade?.id
          }))
          this.isLoading = false
        }).catch((error) => {
          this.$toasted.error(error.message, {duration: 3000})
          this.isLoading = false
        })
      },
    },
  }
</script>

<style>
 .dropdownMenu {
   margin-right: 30px !important;
 }
 .button-style {
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 120% !important;
    padding: 10px 40px !important;
 }

 .btn-bg {
    background-color: var(--dynamic-Background) !important;
 }
</style>
